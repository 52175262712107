import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authenticated
  constructor(
    private router: Router
  ) {
    this.authenticated = localStorage.getItem('token')
    console.log(this.authenticated)
   }

  canActivate() {
    if(this.authenticated) {
     
      return true;
    } 
    else {
      
      this.router.navigateByUrl('/auth/signin');
    }
  }
}
