import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public response: any;
  // private url: string = 'https://j5cug3rrll.execute-api.ap-south-1.amazonaws.com/testing/';
  private url: string = 'https://cz3r0w8bf3.execute-api.ap-south-1.amazonaws.com/prod/';
  public headerstr = new HttpHeaders({'Content-Type': 'application/json'  }) //"Authorization": sessionStorage.getItem("key")
  constructor(
    private http: HttpClient
  ) { }

  login(url, data): Promise<string> {
    return this.http
      .post(this.url + url, data)
      .toPromise()
      .then();
  }

  postService(url, data): Observable<any> {
    return this.http.post(this.url + url, data).pipe(
      map(resp => this.response = resp)
    )
  }

  postmethod(data,url): Observable<any> {
    let newdata = {...data, "Authorization":localStorage.getItem("key")}
    // if (null === localStorage.getItem("key")) {
    //     this.headerstr = new HttpHeaders({ "Authorization": localStorage.getItem("token") })
    //    }
    return this.http.post(this.url + url,newdata).pipe(
      map(resp => this.response = resp)
    )
  }
}
