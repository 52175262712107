import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  // {
  //   id: 'navigation',
  //   title: 'Navigation',
  //   type: 'group',
  //   icon: 'feather icon-monitor',
  //   children: [
  //     {
  //       id: 'dashboard',
  //       title: 'Dashboard',
  //       type: 'collapse',
  //       icon: 'feather icon-home',
  //       children: [
  //         {
  //           id: 'default',
  //           title: 'Default',
  //           type: 'item',
  //           url: '/dashboard/default'
  //         },
  //         {
  //           id: 'sale',
  //           title: 'Sales',
  //           type: 'item',
  //           url: '/dashboard/sale'
  //         },
  //         {
  //           id: 'crm',
  //           title: 'CRM',
  //           type: 'item',
  //           url: '/dashboard/crm'
  //         },
  //         {
  //           id: 'analytics',
  //           title: 'Analytics',
  //           type: 'item',
  //           url: '/dashboard/analytics'
  //         },
  //         {
  //           id: 'project',
  //           title: 'Project',
  //           type: 'item',
  //           url: '/dashboard/project'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'page-layouts',
  //       title: 'Page Layouts',
  //       type: 'collapse',
  //       icon: 'feather icon-layout',
  //       children: [
  //         {
  //           id: 'vertical',
  //           title: 'Vertical',
  //           type: 'collapse',
  //           children: [
  //             {
  //               id: 'v-static',
  //               title: 'Static',
  //               type: 'item',
  //               url: '/layout/static',
  //               target: true,
  //             },
  //             {
  //               id: 'v-fixed',
  //               title: 'Fixed',
  //               type: 'item',
  //               url: '/layout/fixed',
  //               target: true,
  //             },
  //             {
  //               id: 'v-nav-fixed',
  //               title: 'Navbar Fixed',
  //               type: 'item',
  //               url: '/layout/nav-fixed',
  //               target: true,
  //             },
  //             {
  //               id: 'v-collapse-menu',
  //               title: 'Collapse Menu',
  //               type: 'item',
  //               url: '/layout/collapse-menu',
  //               target: true,
  //             },
  //             {
  //               id: 'v-rtl',
  //               title: 'Vertical RTL',
  //               type: 'item',
  //               url: '/layout/vertical-rtl',
  //               target: true
  //             }
  //           ]
  //         },
  //         {
  //           id: 'horizontal',
  //           title: 'Horizontal',
  //           type: 'item',
  //           url: '/layout/horizontal',
  //           target: true
  //         },
  //         {
  //           id: 'horizontal-l2',
  //           title: 'Horizontal v2',
  //           type: 'item',
  //           url: '/layout/horizontal-l2',
  //           target: true
  //         },
  //         {
  //           id: 'horizontal-rtl',
  //           title: 'Horizontal RTL',
  //           type: 'item',
  //           url: '/layout/horizontal-rtl',
  //           target: true
  //         },
  //         {
  //           id: 'box-layout',
  //           title: 'Box Layout',
  //           type: 'item',
  //           url: '/layout/box',
  //           target: true
  //         },
  //         {
  //           id: 'light-layout',
  //           title: 'Light Layout',
  //           type: 'item',
  //           url: '/layout/light',
  //           target: true
  //         },
  //         {
  //           id: 'dark-layout',
  //           title: 'Dark Layout',
  //           type: 'item',
  //           url: '/layout/dark',
  //           target: true,
  //           badge: {
  //             title: 'Hot',
  //             type: 'badge-danger'
  //           }
  //         }
  //       ]
  //     },
  //     {
  //       id: 'widget',
  //       title: 'Widget',
  //       type: 'collapse',
  //       icon: 'feather icon-layers',
  //       badge: {
  //         title: '100+',
  //         type: 'badge-success'
  //       },
  //       children: [
  //         {
  //           id: 'statistic',
  //           title: 'Statistic',
  //           type: 'item',
  //           url: '/widget/statistic'
  //         },
  //         {
  //           id: 'data',
  //           title: 'Data',
  //           type: 'item',
  //           url: '/widget/data'
  //         },
  //         {
  //           id: 'chart',
  //           title: 'Chart',
  //           type: 'item',
  //           url: '/widget/chart'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'users',
  //       title: 'User',
  //       type: 'collapse',
  //       icon: 'feather icon-users',
  //       children: [
  //         {
  //           id: 'profile',
  //           title: 'Profile',
  //           type: 'item',
  //           url: '/users/profile',
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'cards',
  //           title: 'User Card',
  //           type: 'item',
  //           url: '/users/card'
  //         },
  //         {
  //           id: 'list',
  //           title: 'User List',
  //           type: 'item',
  //           url: '/users/list'
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    id: 'main',
    title: 'main',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'feather icon-home',
        url: '/dashboard/default',

      },

      {
        id: 'Customers',
        title: 'Customers',
        type: 'item',
        icon: 'feather icon-user',
        url: '/ecommerce/ecomm-customer'

      },


      {
        id: 'Helpdesk',
        title: 'Helpdesk',
        type: 'item',
        icon: 'feather icon-help-circle',
        url: '/project-crm/pc-customers'

      },


      {
        id: 'data-table',
        title: 'Reports',
        type: 'collapse',
        icon: 'feather icon-grid',

        children: [
          {
            id: 'data-table',
            title: 'Monthly Reports',
            type: 'item',
            // icon: 'feather icon-grid',
            url: '/tbl-datatable'
          },
          {
            id: 'bt-basic',
            title: 'Flat Reports',
            type: 'item',
            url: '/ecommerce/ecomm-product'
          },
        ]
      },



    ]
  },
  // {
  //   id: 'admin-panel',
  //   title: 'Admin Panel',
  //   type: 'group',
  //   icon: 'feather icon-monitor',
  //   children: [
  //     {
  //       id: 'hospital',
  //       title: 'Hospital',
  //       type: 'collapse',
  //       icon: 'feather icon-activity',
  //       children: [
  //         {
  //           id: 'hosp-dashboard',
  //           title: 'Dashboard',
  //           type: 'item',
  //           url: '/hospital/hosp-dashboard'
  //         },
  //         {
  //           id: 'hosp-department',
  //           title: 'Department',
  //           type: 'item',
  //           url: '/hospital/hosp-department'
  //         },
  //         {
  //           id: 'hosp-doctor',
  //           title: 'Doctor',
  //           type: 'item',
  //           url: '/hospital/hosp-doctor'
  //         },
  //         {
  //           id: 'hosp-patient',
  //           title: 'Patient',
  //           type: 'item',
  //           url: '/hospital/hosp-patient'
  //         },
  //         {
  //           id: 'hosp-nurse',
  //           title: 'Nurse',
  //           type: 'item',
  //           url: '/hospital/hosp-nurse'
  //         },
  //         {
  //           id: 'hosp-pharmacist',
  //           title: 'Pharmacist',
  //           type: 'item',
  //           url: '/hospital/hosp-pharmacist'
  //         },
  //         {
  //           id: 'hosp-laboratory',
  //           title: 'Laboratory',
  //           type: 'item',
  //           url: '/hospital/hosp-laboratory'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'project-crm',
  //       title: 'Project & CRM',
  //       type: 'collapse',
  //       icon: 'feather icon-package',
  //       children: [
  //         {
  //           id: 'pc-dashboard',
  //           title: 'Dashboard',
  //           type: 'item',
  //           url: '/project-crm/pc-dashboard'
  //         },
  //         {
  //           id: 'pc-customers',
  //           title: 'Customers',
  //           type: 'item',
  //           url: '/project-crm/pc-customers'
  //         },
  //         {
  //           id: 'pc-project',
  //           title: 'Project',
  //           type: 'item',
  //           url: '/project-crm/pc-project'
  //         },
  //         {
  //           id: 'pc-task',
  //           title: 'Task',
  //           type: 'item',
  //           url: '/project-crm/pc-task'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'membership',
  //       title: 'Membership',
  //       type: 'collapse',
  //       icon: 'feather icon-user-check',
  //       children: [
  //         {
  //           id: 'mshp-dashboard',
  //           title: 'Dashboard',
  //           type: 'item',
  //           url: '/membership/mshp-dashboard'
  //         },
  //         {
  //           id: 'mshp-email',
  //           title: 'Email Templates',
  //           type: 'item',
  //           url: '/membership/mshp-email'
  //         },
  //         {
  //           id: 'mshp-country',
  //           title: 'Country',
  //           type: 'item',
  //           url: '/membership/mshp-country'
  //         },
  //         {
  //           id: 'mshp-coupons',
  //           title: 'Coupons',
  //           type: 'item',
  //           url: '/membership/mshp-coupons'
  //         },
  //         {
  //           id: 'mshp-newsletter',
  //           title: 'Newsletter',
  //           type: 'item',
  //           url: '/membership/mshp-newsletter'
  //         },
  //         {
  //           id: 'mshp-user',
  //           title: 'User',
  //           type: 'item',
  //           url: '/membership/mshp-user'
  //         },
  //         {
  //           id: 'mshp-membership',
  //           title: 'Membership',
  //           type: 'item',
  //           url: '/membership/mshp-membership'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'helpdesk',
  //       title: 'Helpdesk',
  //       type: 'collapse',
  //       icon: 'feather icon-help-circle',
  //       children: [
  //         {
  //           id: 'hd-dashboard',
  //           title: 'Helpdesk Dashboard',
  //           type: 'item',
  //           url: '/helpdesk/hd-dashboard'
  //         },
  //         {
  //           id: 'hd-ticket',
  //           title: 'Create Ticket',
  //           type: 'item',
  //           url: '/helpdesk/hd-ticket'
  //         },
  //         {
  //           id: 'hd-customer',
  //           title: 'Ticket List',
  //           type: 'item',
  //           url: '/helpdesk/hd-customer'
  //         },
  //         {
  //           id: 'hd-customer-detail',
  //           title: 'Ticket Detail',
  //           type: 'item',
  //           url: '/helpdesk/hd-customer-detail'
  //         },
  //         {
  //           id: 'hd-customer-list',
  //           title: 'Customer',
  //           type: 'item',
  //           url: '/helpdesk/hd-customer-list'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'school',
  //       title: 'School',
  //       type: 'collapse',
  //       icon: 'feather icon-book',
  //       badge: {
  //         title: 'New',
  //         type: 'badge-success'
  //       },
  //       children: [
  //         {
  //           id: 'sch-dashboard',
  //           title: 'Dashboard',
  //           type: 'item',
  //           url: '/school/sch-dashboard'
  //         },
  //         {
  //           id: 'sch-student',
  //           title: 'Student',
  //           type: 'item',
  //           url: '/school/sch-student'
  //         },
  //         {
  //           id: 'sch-parents',
  //           title: 'Parents',
  //           type: 'item',
  //           url: '/school/sch-parents'
  //         },
  //         {
  //           id: 'sch-teacher',
  //           title: 'Teacher',
  //           type: 'item',
  //           url: '/school/sch-teacher'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'sis',
  //       title: 'SIS',
  //       type: 'collapse',
  //       icon: 'feather icon-book',
  //       children: [
  //         {
  //           id: 'sis-dashboard',
  //           title: 'Dashboard',
  //           type: 'item',
  //           url: '/sis/sis-dashboard'
  //         },
  //         {
  //           id: 'sis-leave',
  //           title: 'Leave',
  //           type: 'item',
  //           url: '/sis/sis-leave'
  //         },
  //         {
  //           id: 'sis-evaluation',
  //           title: 'Evaluation',
  //           type: 'item',
  //           url: '/sis/sis-evaluation'
  //         },
  //         {
  //           id: 'sis-event',
  //           title: 'Event',
  //           type: 'item',
  //           url: '/sis/sis-event'
  //         },
  //         {
  //           id: 'sis-circular',
  //           title: 'Circular',
  //           type: 'item',
  //           url: '/sis/sis-circular'
  //         },
  //         {
  //           id: 'sis-course',
  //           title: 'Course',
  //           type: 'item',
  //           url: '/sis/sis-course'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'crypto',
  //       title: 'Crypto',
  //       type: 'collapse',
  //       icon: 'feather icon-target',
  //       children: [
  //         {
  //           id: 'cp-dashboard',
  //           title: 'Dashboard',
  //           type: 'item',
  //           url: '/crypto/cp-dashboard'
  //         },
  //         {
  //           id: 'cp-exchange',
  //           title: 'Exchange',
  //           type: 'item',
  //           url: '/crypto/cp-exchange'
  //         },
  //         {
  //           id: 'cp-wallet',
  //           title: 'Wallet',
  //           type: 'item',
  //           url: '/crypto/cp-wallet'
  //         },
  //         {
  //           id: 'cp-transactions',
  //           title: 'Transactions',
  //           type: 'item',
  //           url: '/crypto/cp-transactions'
  //         },
  //         {
  //           id: 'cp-history',
  //           title: 'History',
  //           type: 'item',
  //           url: '/crypto/cp-history'
  //         },
  //         {
  //           id: 'cp-trading',
  //           title: 'Trading',
  //           type: 'item',
  //           url: '/crypto/cp-trading'
  //         },
  //         {
  //           id: 'cp-coin',
  //           title: 'Initial Coin',
  //           type: 'item',
  //           url: '/crypto/cp-coin'
  //         },
  //         {
  //           id: 'cp-ico',
  //           title: 'Ico Listing',
  //           type: 'item',
  //           url: '/crypto/cp-ico'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'ecommerce',
  //       title: 'Ecommerce',
  //       type: 'collapse',
  //       icon: 'feather icon-shopping-cart',
  //       children: [
  //         {
  //           id: 'ecomm-product',
  //           title: 'Product',
  //           type: 'item',
  //           url: '/ecommerce/ecomm-product'
  //         },
  //         {
  //           id: 'ecomm-product-details',
  //           title: 'Product Details',
  //           type: 'item',
  //           url: '/ecommerce/ecomm-product-details'
  //         },
  //         {
  //           id: 'ecomm-order',
  //           title: 'Order',
  //           type: 'item',
  //           url: '/ecommerce/ecomm-order'
  //         },
  //         {
  //           id: 'ecomm-checkout',
  //           title: 'Checkout',
  //           type: 'item',
  //           url: '/ecommerce/ecomm-checkout'
  //         },
  //         {
  //           id: 'ecomm-cart',
  //           title: 'Shopping Cart',
  //           type: 'item',
  //           url: '/ecommerce/ecomm-cart'
  //         },
  //         {
  //           id: 'ecomm-customer',
  //           title: 'Customers',
  //           type: 'item',
  //           url: '/ecommerce/ecomm-customer'
  //         },
  //         {
  //           id: 'ecomm-seller',
  //           title: 'Sellers',
  //           type: 'item',
  //           url: '/ecommerce/ecomm-seller'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: 'ui-element',
  //   title: 'UI ELEMENT',
  //   type: 'group',
  //   icon: 'feather icon-layers',
  //   children: [
  //     {
  //       id: 'basic',
  //       title: 'Basic',
  //       type: 'collapse',
  //       icon: 'feather icon-box',
  //       children: [
  //         {
  //           id: 'alert',
  //           title: 'Alert',
  //           type: 'item',
  //           url: '/basic/alert'
  //         },
  //         {
  //           id: 'button',
  //           title: 'Button',
  //           type: 'item',
  //           url: '/basic/button'
  //         },
  //         {
  //           id: 'badges',
  //           title: 'Badges',
  //           type: 'item',
  //           url: '/basic/badges'
  //         },
  //         {
  //           id: 'breadcrumb-pagination',
  //           title: 'Breadcrumbs & Pagination',
  //           type: 'item',
  //           url: '/basic/breadcrumb-paging'
  //         },
  //         {
  //           id: 'cards',
  //           title: 'Cards',
  //           type: 'item',
  //           url: '/basic/cards'
  //         },
  //         {
  //           id: 'collapse',
  //           title: 'Collapse',
  //           type: 'item',
  //           url: '/basic/collapse'
  //         },
  //         {
  //           id: 'carousel',
  //           title: 'Carousel',
  //           type: 'item',
  //           url: '/basic/carousel'
  //         },
  //         {
  //           id: 'grid-system',
  //           title: 'Grid System',
  //           type: 'item',
  //           url: '/basic/grid-system'
  //         },
  //         {
  //           id: 'progress',
  //           title: 'Progress',
  //           type: 'item',
  //           url: '/basic/progress'
  //         },
  //         {
  //           id: 'modal',
  //           title: 'Modal',
  //           type: 'item',
  //           url: '/basic/modal'
  //         },
  //         {
  //           id: 'spinner',
  //           title: 'Spinner',
  //           type: 'item',
  //           url: '/basic/spinner'
  //         },
  //         {
  //           id: 'tabs-pills',
  //           title: 'Tabs & Pills',
  //           type: 'item',
  //           url: '/basic/tabs-pills'
  //         },
  //         {
  //           id: 'typography',
  //           title: 'Typography',
  //           type: 'item',
  //           url: '/basic/typography'
  //         },
  //         {
  //           id: 'tooltip-popovers',
  //           title: 'Tooltip & Popovers',
  //           type: 'item',
  //           url: '/basic/tooltip-popovers'
  //         },
  //         {
  //           id: 'toasts',
  //           title: 'Toasts',
  //           type: 'item',
  //           url: '/basic/toasts'
  //         },
  //         {
  //           id: 'other',
  //           title: 'Other',
  //           type: 'item',
  //           url: '/basic/other'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'advance',
  //       title: 'Advance',
  //       type: 'collapse',
  //       icon: 'feather icon-gitlab',
  //       children: [
  //         {
  //           id: 'sweet-alert',
  //           title: 'Sweet Alert',
  //           type: 'item',
  //           url: '/advance/alert'
  //         },
  //         {
  //           id: 'task-board',
  //           title: 'Task Board',
  //           type: 'item',
  //           url: '/advance/task-board'
  //         },
  //         {
  //           id: 'light-box',
  //           title: 'Light Box',
  //           type: 'item',
  //           url: '/advance/light-box'
  //         },
  //         {
  //           id: 'notification',
  //           title: 'Notification',
  //           type: 'item',
  //           url: '/advance/notification'
  //         },
  //         {
  //           id: 'rating',
  //           title: 'Rating',
  //           type: 'item',
  //           url: '/advance/rating'
  //         },
  //         {
  //           id: 'range-slider',
  //           title: 'Range Slider',
  //           type: 'item',
  //           url: '/advance/range-slider'
  //         },
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: 'forms',
  //   title: 'Forms',
  //   type: 'group',
  //   icon: 'feather icon-layout',
  //   children: [
  //     {
  //       id: 'forms-element',
  //       title: 'Forms',
  //       type: 'collapse',
  //       icon: 'feather icon-file-text',
  //       children: [
  //         {
  //           id: 'form-elements',
  //           title: 'Form Elements',
  //           type: 'item',
  //           url: '/forms/basic'
  //         },
  //         {
  //           id: 'form-elements',
  //           title: 'Form Advance',
  //           type: 'item',
  //           url: '/forms/advance'
  //         },
  //         {
  //           id: 'form-validation',
  //           title: 'Form Validation',
  //           type: 'item',
  //           url: '/forms/validation'
  //         },
  //         {
  //           id: 'form-masking',
  //           title: 'Form Masking',
  //           type: 'item',
  //           url: '/forms/masking'
  //         },
  //         {
  //           id: 'form-wizard',
  //           title: 'Form Wizard',
  //           type: 'item',
  //           url: '/forms/wizard'
  //         },
  //         {
  //           id: 'form-picker',
  //           title: 'Form Picker',
  //           type: 'item',
  //           url: '/forms/picker'
  //         },
  //         {
  //           id: 'form-select',
  //           title: 'Form Select',
  //           type: 'item',
  //           url: '/forms/select'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   id: 'table',
  //   title: 'Table',
  //   type: 'group',
  //   icon: 'feather icon-list',
  //   children: [
  //     {
  //       id: 'bootstrap',
  //       title: 'Bootstrap',
  //       type: 'collapse',
  //       icon: 'feather icon-server',
  //       children: [
  //         {
  //           id: 'bt-basic',
  //           title: 'Basic Table',
  //           type: 'item',
  //           url: '/tbl-bootstrap/bt-basic'
  //         },
  //         {
  //           id: 'bt-sizing',
  //           title: 'Sizing Table',
  //           type: 'item',
  //           url: '/tbl-bootstrap/bt-sizing'
  //         },
  //         {
  //           id: 'bt-border',
  //           title: 'Border Table',
  //           type: 'item',
  //           url: '/tbl-bootstrap/bt-border'
  //         },
  //         {
  //           id: 'bt-styling',
  //           title: 'Styling Table',
  //           type: 'item',
  //           url: '/tbl-bootstrap/bt-styling'
  //         }
  //       ]
  //     },
  //     {
  //       id: 'data-table',
  //       title: 'Data Table',
  //       type: 'item',
  //       icon: 'feather icon-grid',
  //       url: '/tbl-datatable'
  //     }
  //   ]
  // },

  // {
  //   id: 'pages',
  //   title: 'Pages',
  //   type: 'group',
  //   icon: 'feather icon-file-text',
  //   children: [
  //     {
  //       id: 'auth',
  //       title: 'Authentication',
  //       type: 'collapse',
  //       icon: 'feather icon-lock',
  //       children: [
  //         {
  //           id: 'signup',
  //           title: 'Sign up',
  //           type: 'item',
  //           url: '/auth/signup',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'signup-v2',
  //           title: 'Sign up v2',
  //           type: 'item',
  //           url: '/auth/signup-v2',
  //           badge: {
  //             title: 'New',
  //             type: 'badge-primary'
  //           },
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'signin',
  //           title: 'Sign in',
  //           type: 'item',
  //           url: '/auth/signin',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'signin-v2',
  //           title: 'Sign in v2',
  //           type: 'item',
  //           url: '/auth/signin-v2',
  //           badge: {
  //             title: 'New',
  //             type: 'badge-primary'
  //           },
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'reset-password',
  //           title: 'Reset Password',
  //           type: 'item',
  //           url: '/auth/reset-password',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'reset-password-v2',
  //           title: 'Reset Password v2',
  //           type: 'item',
  //           url: '/auth/reset-password-v2',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'change-password',
  //           title: 'Change Password',
  //           type: 'item',
  //           url: '/auth/change-password',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'change-password-v2',
  //           title: 'Change Password v2',
  //           type: 'item',
  //           url: '/auth/change-password-v2',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'personal-information',
  //           title: 'Personal Information',
  //           type: 'item',
  //           url: '/auth/personal-information',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'profile-settings',
  //           title: 'Profile Settings',
  //           type: 'item',
  //           url: '/auth/profile-settings',
  //           target: true,
  //           breadcrumbs: false
  //         }
  //       ]
  //     },
  //     {
  //       id: 'maintenance',
  //       title: 'Maintenance',
  //       type: 'collapse',
  //       icon: 'feather icon-sliders',
  //       children: [
  //         {
  //           id: 'error',
  //           title: 'Error',
  //           type: 'item',
  //           url: '/maintenance/error',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'offline-ui',
  //           title: 'Offline UI',
  //           type: 'item',
  //           url: '/maintenance/offline-ui',
  //           target: true,
  //           breadcrumbs: false
  //         },
  //         {
  //           id: 'coming-soon',
  //           title: 'Maintenance',
  //           type: 'item',
  //           url: '/maintenance/coming-soon',
  //           target: true,
  //           breadcrumbs: false
  //         }
  //       ]
  //     }
  //   ]
  // },



];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
